@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@600&display=swap");

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #fafeff;
  user-select: none;
}

.blurDemo {
  width: 477px;
  text-align: center;
  font-family: "Prompt", sans-serif;
}

.phoneContainer {
  position: relative;
  margin: 50px 0px 30px 0px;
  max-width: 477px;
  max-height: 433px;
}

.maskBlur {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 130px;
  left: 50%;
  mask: linear-gradient(black, black, transparent);
  -webkit-mask: linear-gradient(black, black, transparent);
  width: 397px;
  height: 75px;
}

.normal {
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.dramatic {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.phone {
  width: 100%;
}

.call {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 18%;
  left: 50%;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.3));
}

.camera {
  position: absolute;
  top: 37px;
  left: 263px;
  width: 38px;
  height: 38px;
  z-index: 93000000;
}

.navImage {
  position: absolute;
  width: 55px;
  height: 55px;
  left: 55px;
  top: 52px;
}

.toggleBlurContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 10px;
  background: rgba(206, 207, 218, 0.15);
  border: 1px solid rgba(206, 207, 218, 0.5);
  border-radius: 18px;
  height: 60px;
  transition: 0.5s;
}

.toggleBlurContainer.active {
  background: rgba(252, 85, 172, 0.1);
  border: 1px solid #fc55ac;
}

@media screen and (max-width: 500px) {
  .App {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .blurDemo {
    margin: 0 25px 0 25px;
  }
  .call {
    width: 83.3%;
  }
  .navImage {
    top: 11.5%;
    left: 11.5%;
    width: 11%;
    height: 12%;
  }
  .camera {
    left: 55%;
    top: 8%;
    width: 8%;
    height: 9%;
  }
  .maskBlur {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 31%;
    left: 50%;
    mask: linear-gradient(black, black, transparent);
    width: 88%;
    height: 18%;
  }
}
